import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";


const BizDev = ({pageContext}) => {
    return <Layout
        pageContext={pageContext}
    >
        <Seo title={"Business Development"} />
        <div className="content-row bizdev">
            <div className="seventy">
                <h1>VanWalks Business Opportunities</h1>
                <p className="sub-title">
                    We want to work with you!
                </p>
                <div className="bizdev-item">
                    <h2>Street Level Sales Reps</h2>
                    <p className="sub-title">
                        Join the VanWalks Sales Rep team and earn commissions on every sale you make! Share your passion for Vancouver and earn money doing it. Sign up now and start earning.
                    </p>
                    <a className="learn-more" href="/business/reps">
                        Learn More
                    </a>
                </div>
                <div className="bizdev-item">
                    <h2>Bulk Sales for Conferences</h2>
                    <p className="sub-title">
                        VanWalks offers volume discounts on our premium content for conferences and meetings in Vancouver. Our user-friendly web app makes it easy to integrate VanWalks into your conference schedule, allowing your attendees to explore Vancouver at their own pace. Plus, our self paced walking tours can accommodate much larger groups compared to traditional walking tours and group events. Host Chris King is also available for kick off events and guidance.
                    </p>
                    <a className="learn-more" href="/business/conferences">
                        Learn More
                    </a>
                </div>
                <div className="bizdev-item">
                    <h2>Business Partnerships</h2>
                    <p className="sub-title">
                    At VanWalks, we offer a variety of partnership opportunities to help businesses provide unique and engaging experiences for their customers and employees. From discounted premium content bundles to custom branded routes, we have a range of options to fit your needs. Our web-based platform is nimble and flexible, making it easy to create custom solutions that work for you. Let&apos;s work together to create something special.
                    </p>
                    <a className="learn-more" href="/business/partners">
                        Learn More
                    </a>
                </div>
            </div>
            <div className="thirty screenshot-bizdev">
                <StaticImage
                    className="screenshot screenshot-1"
                    src="../../images/home_screenshot_1.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
                <StaticImage
                    className="screenshot screenshot-2"
                    src="../../images/home_screenshot_2.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
            </div>
        </div>
    </Layout>
}

export default BizDev